import React from 'react';
import { Result, Button } from 'antd';
import { useRouter } from 'next/router';

const Custom404: React.FC = () => {
  const router = useRouter();
  return (
    <Result
      status="404"
      title="404"
      subTitle="该页面未找到"
      extra={
        <Button
          type="primary"
          onClick={() => {
            router.push('/');
          }}
        >
          返回首页
        </Button>
      }
    />
  );
};
export default Custom404;
